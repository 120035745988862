<template>
    <div>
		<div class="experience-card">
			<h3>Lead Product Manager</h3>
			<h5><i>MadeTech</i> | <i>August 2023 to present</i> | <i>Internal promotion</i></h5>
			<ul><li>Led lean team in experimental approach to introducing a new 'zero to one'
<a href="https://voids.madetech.com/">B2B social housing product</a>, and bringing it to market </li>

			<li>Led user research into needs of our customers to ensure accurate value propositions were made</li>
			<li>Conducted outreach to customers and established marketing, GTM and pricing tactics</li>
			<li>Set strategic focus and roadmap for the product</li>
			<li>Public face of the product, hosting webinar discussion panels and representing the company at conferences</li>
			<li>Managed cross functional teams throughout software development lifecycle</li>
			<li>Developed project playbook for Home Office MAPPS reporting function</li>

			</ul>
		</div>
		<div class="experience-card">
			<h3>Senior Product Manager</h3>
			<h5><i>MadeTech</i> | <i>Jan 2022 to August 2023</i></h5>
			<ul>
				<li>Delivered DVLA data lake & discovery on NHS data API product</li>
				<li>Liaised with senior DVLA officials on data strategy to agree product strategy and agree with senior internal stakeholders</li>
				<li>Jointly established Community of Practice with Head of Product</li>
				<li>Coached client delivery teams in agile practice including moving to iterative fortnightly releases, and user focussed tickets</li>
				<li>Line managed 2 Product Managers to successful promotion</li>

			</ul>
		</div>
		<div class="experience-card">
			<h3>Senior Product Manager</h3>
			<h5><i>Arctoris</i> | <i>Aug 2021 to Jan 2022</i></h5>
			<ul>
				<li>Moved engineering team to an agile framework. This was helped by ensuring early on that deploy times were dramatically reduced by documenting the deploy procedure</li>
			</ul>
		</div>
		<div class="experience-card">
			<h3>Senior Product Manager</h3>
			<h5><i>Zen Educate</i> | <i>Jan 2020 to Aug 2021</i></h5>
			<ul>
				<li>Managed three teams (web, native, API) to deliver higher automation, better liquidity, and over 1,000,000 transactions for marketplace app</li>
				<li>Led user research sessions with schools and teachers to improve UI/UX resulting in 30% reduction in 'time-to-book' KPI</li>
				<li>Managed and trained junior Product Manager</li>
				<li>Complete management of backlog and sprint planning process</li>
			</ul>
		</div>

		<div class="experience-card">
			<h3>Head of Digital Organising (Interim)</h3>
			<h5><i>The Labour Party</i> | <i>Jan 2019 to Jun 2019</i></h5>
			<ul>
				<li>Delivered, and managed the cross-functional team of 7 that delivered 5 'zero to one' <a href="https://labour.org.uk/members/activist-area/tools-for-activists/">applications that mobilised 100,000s of volunteers</a> and millions of voters.</li>
			</ul>
		</div>
		
		<div class="experience-card">
			<h3>Further experience</h3>
			<i><h5>2012 to present</h5></i>
			<ul>
				<li>Product Manager - The Labour Party</li>
				<li>Campaign Manager - Gemma Doyle MP</li>
				<li>West of Scotland Campaign Manager - Better Together</li>
				<li>Freelance Web Developer - Multiple Clients</li>
			</ul>
		</div>

		<div class="experience-card">
			<h3>University of Glasgow</h3>
			<h5><i>LL.B in Law with Honours</i> | <i>'08 to '12</i></h5>
			<ul>
				<li>Upper Second Class Degree in Law.</li>
			</ul>
		</div>
    </div>
</template>


<script>

	export default {
		name: "ExperienceDetails"
		}

</script>


<style>
.experience-card {
  text-align: left;
  padding-bottom: 20px;
}

li {
	padding-bottom: 7px;
}

@media only screen and (max-width: 767px) {
	.experience-card h3 {
		text-align: center;
	}
}

@media only screen and (max-width: 767px) {
	.experience-card h5 {
		text-align: center;
	}
}
</style>
