<template>
    <div class="highlights">

		<p>Brought new social housing B2B product to market from discovery through to MVP, setting strategic vision and business proposition</p>

		<p>Managed backlog, strategic planning and delivery for teacher-school marketplace which handled data on over 20,000 teachers and 2,000 schools across the UK, delivering 270% YoY increase in bookings.</p>

		<p>Ran election technology for the Labour Party 2017 and 2019 General Election including personally building volunteer.labour.org.uk</p>

		<p>Led development application holding 2 million people's sensitive personal data and managed support for over 10,000 users, and managed development of mass telephony service with over 10,000 users, holding personal information on over 5 million people</p>

		<p>Wrote strategic plan, roadmap, and specification for the Labour Party's new in-house CRM</p>

		<p>Key liaison and pivot point between senior management and technical colleagues on development strategy</p>

		<p>Coached public sector delivery teams in agile practices, and established community of practice for MadeTech product managers</p>

		<p>Established data strategy and roadmap for DVLA data programme</p>

    </div>
</template>


<script>

	export default {
		name: "WorkHighlights"
	}

</script>


<style>

</style>